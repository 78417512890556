<template>
     <div class="home bgf2">
          <div v-if="this.$global.isPc">
               <!-- 视频 -->
               <div class="video">
                    <video ref="video" :src="video_path" class="video width1" controls autoplay muted  style="object-fit: cover;"></video>
               </div><div class="banner margin-top-4">
                    <!-- banner图 @click="clickBannerinfo(item)" -->
                    <div class="swiper-container">
                         <div class="swiper-wrapper width1">
                              <div class="swiper-slide width1001" :data-item="item.id" v-for="(item,index) in banner" :key="index"><img class="img" :src="item.image" alt=""></div>
                         </div>
                         <div class="swiper-pagination"></div>
                         <div class="swiper-button-next"></div>
                         <div class="swiper-button-prev"></div>
                    </div>
               </div>
               <!-- 展会图片 -->
               <div class="text-left padding01 margin-top20 flex flexcenter container">
                    <span class="flex-1"></span>
                    <span class="font-size-20 font-weight text-center" v-if="$global.lang==1">展会图片</span>
                    <span class="font-size-20 font-weight text-center" v-else>Exhibition pictures</span>
                    <span class="flex-1 text-right">
                         <span @click="clickmore()" class="cursor" v-if="$global.lang==1">更多<i class="icon iconfont icon-xiangyoujiantou font-size-10 margin-left5"></i></span>
                         <span @click="clickmore()" class="cursor" v-else>More<i class="icon iconfont icon-xiangyoujiantou font-size-10 margin-left5"></i></span>
                    </span>
                    
               </div>
               <div class="product flex flexcenter flexcenter1 flexwrap ispc padding01 container">
                    <div class="padding01 animate__animated animate__fadeInUp" v-for="(item,index) in homelist" :key="index" @click="clickproduct(item)" v-if="index<9">
                         <div v-if="item.image"><img class="img imgH" v-lazy="item.image" alt=""></div>
                         <div v-if="item.image">{{ item.name }}</div>
                    </div>
               </div>
               <div class="margin40-0 bgWhite padding40" v-if="$global.lang==1">
                    <span class="flex-1 font-size-20 font-weight text-center">关于我们</span>
                    <div class="prewrap container" v-html="company_content"></div>
               </div>
               <div class="margin40-0 bgWhite padding40" v-else>
                    <span class="flex-1 font-size-20 font-weight text-center">About</span>
                    <div class="prewrap container" v-html="company_content_en"></div>
               </div>
          </div>
          <div v-else class="ismobile">
               <div class="swiper-container homebanner width" style="overflow: auto;overflow-x: hidden;">
                    <div class="swiper-wrapper">
                         <div class="swiper-slide">
                              <!-- banner图 -->
                              <div class="banner">
                                   <div class="swiper-container width">
                                        <div class="swiper-wrapper">
                                             <div class="swiper-slide" :data-item="item.id" v-for="(item,index) in banner" :key="index"><img class="img" :src="item.m_image" alt=""></div>
                                        </div>
                                   </div>
                                   <div class="position-abso bottom-5 animation-updown width1001 z-index-1"><i class="icon iconfont icon-downward font-size-42 color-white"></i></div>
                              </div>
                         </div><div ref="scrollWrap" class="swiper-slide container" :class="{scrollWrap: isnoSwiping}" @scroll="handleScroll"  style="overflow: auto">
                              <div class="video width1001">
                                   <video ref="video" :src="video_path" class="video width1001" controls autoplay ></video>
                              </div>
                              <!-- 展会图片 -->
                              <div class="text-left padding01 margin-top20 flex flexcenter">
                                   <span class="flex-1"></span>
                                   <span class="font-size-20 font-weight text-center" v-if="$global.lang==1">展会图片</span>
                                   <span class="font-size-20 font-weight text-center" v-else>Exhibition pictures</span>
                                   <span class="flex-1 text-right" @click="clickmore()" v-if="$global.lang==1">更多 <i class="icon iconfont icon-xiangyoujiantou font-size-10"></i></span>
                                   <span class="flex-1 text-right" @click="clickmore()" v-else>More <i class="icon iconfont icon-xiangyoujiantou font-size-10"></i></span>
                              </div>
                              <div class="product flex flexwrap width1001">
                                   <div class="padding01 animate__animated animate__fadeInUp" v-for="(item,index) in homelist" :key="index" @click="clickproduct(item)" v-if="index<8">
                                        <div v-if="item.image"><img class="img imgH" :src="item.image" alt=""></div>
                                        <div>{{ item.name }}</div>
                                   </div>
                              </div>
                              <div class="margin40-0 bgWhite padding40 aboutus" v-if="$global.lang==1">
                                   <span class="flex-1 font-size-20 font-weight text-center">关于我们</span>
                                   <div class="prewrap container" v-html="company_content"></div>
                              </div>
                              <div class="margin40-0 bgWhite padding40 aboutus" v-else>
                                   <span class="flex-1 font-size-20 font-weight text-center">About</span>
                                   <div class="prewrap container" v-html="company_content_en"></div>
                              </div>
                         <div class="bgf2">
                              <div class="flex flexcenter flexcenter1 line-height padding80 container footerSt" v-if="$global.lang==1">
                                   <div class="text-left flex-1 margin-left5">
                                        <div>{{ footer_info.title }}</div>
                                        <div>营业执照：{{ footer_info.business_license }}</div>
                                        <div>备案号码：{{ footer_info.beian }}</div>
                                        <div>地址：{{ footer_info.address }}</div>
                                   </div>
                                   <div class="text-left margin-left5 flex-1">
                                        <div class="font-size-20">联系我们</div>
                                        <div>电话：{{ footer_info.mobile }}</div>
                                        <div>邮箱：{{ footer_info.email }}</div>
                                        <div>Q  Q：{{ footer_info.qq }}</div>
                                   </div>
                                   <div class="">
                                        <div><img class="animate__animated animate__fadeInUp img-qrcode" v-lazy="footer_info.customer_image" alt=""></div>
                                   </div>
                              </div>
                              <div class="flex flexcenter flexcenter1 line-height padding80 container footerSt" v-else>
                                   <div class="text-left flex-1 margin-left5">
                                        <div>{{ footer_info.title_en }}</div>
                                        <div>Business License：{{ footer_info.business_license }}</div>
                                        <div>ICP Record Number：{{ footer_info.beian }}</div>
                                        <div>Address：{{ footer_info.address_en }}</div>
                                   </div>
                                   <div class="text-left margin-left5 flex-1">
                                        <div class="font-size-20">Contact Us</div>
                                        <div>Tel：{{ footer_info.mobile }}</div>
                                        <div>Email：{{ footer_info.email }}</div>
                                        <div>Q  Q：{{ footer_info.qq }}</div>
                                   </div>
                                   <div class="">
                                        <div><img class="animate__animated animate__fadeInUp img-qrcode" v-lazy="footer_info.customer_image" alt=""></div>
                                   </div>
                              </div>
                         </div>
<!--                              <div v-if="loadingMore" class="loading-more text-center">-->
<!--                                   加载中...-->
<!--                              </div>-->
<!--                              <div v-if="!havedata" class="loading-more text-center">-->
<!--                                   ~ 没有更多了 ~-->
<!--                              </div>-->
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import Swiper from 'swiper'
import "swiper/dist/css/swiper.css";
export default {
     name: 'home',
     data(){
          return {
               autoScrollTop: true,
               isnoSwiping: true,
               loadingMore: false,
               isPc: false,
               banner:[],
               video_path: '',
               company_content: '',
               company_content_en: '',
               coverImageUrl: '',
               Swipersave:'',
               bottom_image: '',
               footer_info:'',
               showN:0,
               show:true,
               isUsed:false,
               homelist: [],
               p:1,
               havedata:true
          }
     },
     watch:{
          homelist:function(newd,oldd){
               // console.log(oldd,'旧数据')
               // console.log(newd,'新数据')
               if(newd.length<6){
                    let len = 6-newd.length
                    for(let i=0;i<len;i++){
                         this.homelist = [...this.homelist,...[{}]]
                    }
               }
               // console.log(this.homelist,'111')
          }
     },
     created(){
          this.init()
     },
     mounted(){
          //在窗口滚动时调用监听窗口滚动方法
          this.$nextTick(() => {
          this.getSwiper()
          })
          if(this.$global.isBack){
               // this.$bus.$on('backshow','');
               this.getSwiperhome(1)
          }else{
               this.getSwiperhome(this.showN)
          }
     },
     methods: {
          async init(){
               // console.log(222)
               let that = this
               await this.$http.get('/api/index/home?p='+this.p).then(
               res => {
                    that.homelist = [...that.homelist,...res.data.data.product]
                    that.banner = res.data.data.banner
                    that.footer_info = res.data.data.info
                    that.company_content = res.data.data.info.company_content
                    that.company_content_en = res.data.data.info.company_content_en
                    that.video_path = res.data.data.info.video
                    that.coverImageUrl = res.data.data.info.video_cover
                    that.bottom_image = res.data.data.info.customer_image
                    if(res.data.data.product.length==0) that.havedata = false
               },
               error=>{}
               )
          },
          clickproduct(item){
               this.$router.push({name:'info',query:{id:item.id}})
          },
          clickmore(){
               this.$router.push({name:'homelist'}) 
          },
          clickBannerinfo(item){
               console.log('clickBannerinfo')
          },
          getSwiper() {
               let that = this
               new Swiper(".banner .swiper-container", {
                      loop: false, // 无缝
                      autoplay: 2000,
                    //   disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                      // paginationClickable: true,
                    // 设置滚动方向为纵向
                    // direction: 'vertical',
                    // preventClicks: false,
                      slidesPerView: 1, // 一组三个
                      // 如果需要前进后退按钮
                    //   nextButton: '.banner .swiper-button-next',
                    //   prevButton: '.banner .swiper-button-prev',
                      // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
                      observer: true,
                      observeParents: true,
                      // 如果需要分页器
                    //   pagination: '.banner .swiper-pagination',
                    //   preventDefault: false
                    
                    onClick: function(e){
                         let id = e.clickedSlide.attributes["data-item"].nodeValue
                         that.$router.push({name:'bannerlist',query:{id:id}})
                         // console.log(e,'jahhahahah')
                    }
                  })
              },
          getSwiperhome(n) {
               // console.log(n,'getSwiperhome')
               let that = this
                  this.Swipersave = new Swiper(".homebanner.swiper-container", {
                      loop: false, // 无缝
                    //   autoplay: 2000,
                      disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                      initialSlide:n,
                      // paginationClickable: true,
                    // 设置滚动方向为纵向
                    direction: 'vertical',
                      slidesPerView: 1, // 一组三个
                      // 如果需要前进后退按钮
                      // nextButton: '.homebanner .swiper-button-next',
                      // prevButton: '.homebanner .swiper-button-prev',
                      // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
                      observer: true,
                      observeParents: true,
                      // 如果需要分页器
                      // pagination: '.homebanner .swiper-pagination',
                      noSwiping : true,
                      noSwipingClass : 'scrollWrap',
                      // mousewheelControl: true,
                      // disableMousewheelControl: function(){},
                    //   preventDefault: false
                       onTouchEnd: function(e){
                           if(that.Swipersave.activeIndex==0){
                                that.isnoSwiping = true
                                that.$refs.scrollWrap.scrollTop = 1
                                that.autoScrollTop = false
                           }
                            that.$bus.$emit('swiperEvent',that.Swipersave.activeIndex);
                       },
                       // onSetTransition: function(swiper) {
                       //      swiper.disableTouchControl();
                       // },
                       // onTransitionEnd: function(swiper) {
                       //      swiper.enableTouchControl();
                       // }
                  })
              },
              disableTouchControl(){
               //     console.log('禁用')
               // this.Swipersave.disableTouchControl()
                   // console.log(this.Swipersave)
                   this.isnoSwiping = false
                   if(!this.autoScrollTop){
                        this.Swipersave.slideTo(0)
                   }
                   this.autoScrollTop = true
              },
              enableTouchControl(){
               // console.log('不禁用')
               // this.Swipersave.enableTouchControl()
                   this.isnoSwiping = true
              },
               handleScroll(e){
                    // console.log(e)
                    let scrollTop = e.target.scrollTop
                    // console.log(scrollTop)
                    if(scrollTop > 0){
                         this.enableTouchControl()
                    }else{
                         this.disableTouchControl()
                    }
               },
              // handleScroll(e){
              //       // console.log(e)
              //       const { scrollTop, scrollHeight, clientHeight } = e.target;
              //       if (this.loadingMore) return;
              //       if(!this.havedata) return;
              //       // 当滚动到底部时，加载更多
              //       if (scrollTop + clientHeight >= scrollHeight - 5) { // 留5px的缓冲区
              //            this.loadMore();
              //       }
              // },
              loadMore(){
               this.loadingMore = true
               this.p++
               this.init()
               this.loadingMore = false
              }
     }
     
}
</script>
<style>
     .aboutus div>p>img{max-width: 100% !important}
</style>
<style scoped>
.product.ispc>div{width: 31%;}
.product.ispc .padding01{padding: 0;}
/*.product.ispc .padding01:nth-child(2n+1){padding-right: 0;}*/
.product.ispc .padding01:nth-child(2n){padding-left: 0;}
.product.ispc .padding01{padding: 1%;padding-top: 0;padding-left: 0;padding-right: 2.5%;}
.product.ispc .padding01:nth-child(3n){padding-right: 0px}
/* 上下晃动 */
.animation-updown{animation: updown 2s infinite ease-in-out;}
@keyframes updown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
</style>