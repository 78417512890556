<template>
  <div id="app">
    <!-- 顶部导航 -->
    <div class="nav flex width1001 flexcenter1 flexcenter" :class="{bgWhite:isVisable,shadow:isNotHome}">
        <!--    电脑端    -->
        <div class="flex" v-if="this.$global.isPc">
            <div class="nav-item" :class="{active:path=='/'}" @click="clickNav({path:'/'})" v-if="lang==1">首页</div>
            <div class="nav-item" :class="{active:path=='/'}" @click="clickNav({path:'/'})" v-else>Home</div>
            <div class="nav-item" :class="{active:path==item.path}" v-for="(item,index) in navList" :key="index" @click="clickNav(item)">
                <div v-if="lang==1">{{ item.name }}</div>
                <div v-else>{{ item.name_en }}</div>
            </div>
        </div>
        <!--    移动端    -->
        <div v-else class="text-left bg-white position-abso right10 top-center">
            <div class="icon iconfont icon-caidansangehengxian color-red font-size-20" @click="clickCloseShowHome()"></div>
        </div>
        <div class="text-left bg-white showhome-nav" v-if="showhome">
            <div class="position-abso right10 top10 icon iconfont icon-quxiao font-size-20" @click="clickCloseShowHome()"></div>
            <div class="con">
                <div @click="clickNav({path:'/'})"  v-if="lang==1">首页</div>
                <div @click="clickNav({path:'/'})"  v-else>Home</div>
                <div class="" :class="{activem:path==item.path}" v-for="(item,index) in navList" :key="index" @click="clickNav(item)">
                    <div v-if="lang==1">{{ item.name }}</div>
                    <div v-else>{{ item.name_en }}</div>
                </div>
            </div>
        </div>
      <div>
        <!-- <div class="select"></div> -->
        <select class="select" @change="langChange()" v-model="lang">
          <option value="1" selected>Chinese</option>
          <option value="2" >English</option>
        </select>
      </div>
        <div>

        </div>
    </div>
    <router-view/>
    <!-- 联系我们 -->
     <div class="bgf2" v-if="isNotHome">
      <div class="flex flexcenter flexcenter1 line-height padding80 container" :class="{footerSt:!$global.isPc}" v-if="$global.lang==1">
                  <div class="text-left flex-1 margin-left5">
                    <div>{{ footer_info.title }}</div>
                    <div>营业执照：{{ footer_info.business_license }}</div>
                    <div>备案号码：{{ footer_info.beian }}</div>
                    <div>地址：{{ footer_info.address }}</div>
                  </div>
                  <div class="text-left margin-left5 flex-1">
                    <div class="font-size-20">联系我们</div>
                    <div>电话：{{ footer_info.mobile }}</div>
                    <div>邮箱：{{ footer_info.email }}</div>
                    <div>Q  Q：{{ footer_info.qq }}</div>
                  </div>
                  <div class="">
                    <div><img class="animate__animated animate__fadeInUp img-qrcode" v-lazy="footer_info.customer_image" alt=""></div>
                  </div>
               </div>
               <div class="flex flexcenter flexcenter1 line-height padding80 container" :class="{footerSt:!$global.isPc}" v-else>
                  <div class="text-left flex-1 margin-left5">
                    <div>{{ footer_info.title_en }}</div>
                    <div>Business License：{{ footer_info.business_license }}</div>
                    <div>ICP Record Number：{{ footer_info.beian }}</div>
                    <div>Address：{{ footer_info.address_en }}</div>
                  </div>
                  <div class="text-left margin-left5 flex-1">
                    <div class="font-size-20">Contact Us</div>
                    <div>Tel：{{ footer_info.mobile }}</div>
                    <div>Email：{{ footer_info.email }}</div>
                    <div>Q  Q：{{ footer_info.qq }}</div>
                  </div>
                  <div class="">
                    <div><img class="animate__animated animate__fadeInUp img-qrcode" v-lazy="footer_info.customer_image" alt=""></div>
                  </div>
               </div>
     </div>
               
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
        showhome:false,
      lang:1,
      bottom_image: require('./assets/2.jpg'),
      path:'/',
      ishref: false,
      navList:[
        {
          path: '/productlist',
          name: '产品',
          name_en: 'product',
          child_paths: '/productinfo'
        },
        {
          path: '/about',
          name: '关于我们',
          name_en: 'about us'
        }
      ],
      footer_info:'',
      isVisable: false,
      isNotHome: false
    }
  },
  created(){
    this.init()
    //在窗口滚动时调用监听窗口滚动方法
    this.$nextTick(() => {
      window.addEventListener('scroll', this.windowScrollListener, true);
    })
      this.$bus.$on('swiperEvent',this.swiperEven);
    this.$router.afterEach((to,from)=>{
      this.path = to.fullPath
      if(to.fullPath != '/'){
          this.isNotHome = true;
          this.isVisable = true; 
      }else{
          this.isNotHome = false;
          this.windowScrollListener()
      }
      // if(item.child_paths){
      //   let child_paths = item.child_paths+'-';
      //   if(child_paths.indexOf(this.path+'-')>-1){
          
      //   }
      // }
      
    })
  },
  methods:{
      swiperEven(d){
          if(d==0) this.isVisable = true;
          else this.isVisable = false;
      },
      clickCloseShowHome(){
          this.showhome = !this.showhome
      },
    init(){
      let lang = localStorage.getItem('lang')
      if(lang && lang !== 'null') this.lang = lang
      else localStorage.setItem('lang',this.lang)
      this.$global.lang = this.lang
      this.$http.defaults.headers.Language = this.lang
      console.log(this.$global.lang,'切换语言') 
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        this.$global.isPc = false
      } else {
        this.$global.isPc = true
      }
      let that = this
      this.$http.get('/api/index/index').then(
        res => {
          that.footer_info = res.data.data
          this.$global.company_info = res.data.data
          this.$bus.$emit('companyEvent',res.data.data);
        },
        error=>{}
      )
    },
    langChange(){
      this.$global.lang = this.lang
      localStorage.setItem('lang',this.lang)
      this.$http.defaults.headers.Language = this.lang
      location.reload()
    },
    clickNav(item){
      if(this.ishref) return;
      this.ishref = true;
      if(item.path != this.path){
        let p = item.path.replace('/','')
        if(p.length>0){
          this.$router.push({name:p})
        }else{
          this.$router.push('/')
        }
      }
      this.ishref = false;
      if(this.showhome) this.showhome = !this.showhome
    },
    windowScrollListener(){
              if(this.isNotHome) return;
              //获取操作元素最顶端到页面顶端的垂直距离
              var scrollTop = Math.floor(document.documentElement.scrollTop || document.body.scrollTop || document.querySelector('#app').scrollTop);
              // console.log(scrollTop, "scrollTop");
              if (scrollTop >= 65) {
                this.isVisable = true; 
              }else {
                this.isVisable = false;
              }
            }
  }

}
</script>

<style>
body{margin: 0px !important;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
/* background: #187AB0; */
.nav{position: fixed;top: 0;left: 0;margin: 0 auto;width: 100%;z-index: 99;}
.nav.shadow{box-shadow: 1px 1px 2px #F9F9F9}
.nav .nav-item{padding: 20px;cursor: pointer;}
.nav .active{border-bottom: 2px solid #187AB0;color: #187AB0;}
.nav .activem{color: #187AB0;}
.font-size-42{font-size: 42px !important;}
.font-size-30{font-size: 30px;}
.font-size-20{font-size: 20px !important;}
.font-size-10{font-size: 10px !important;}
.font-weight{font-weight: 600;}
.text-left{text-align: left;}
.top-img{width: 100%;height: 30vh;background: url(assets/top.jpg) no-repeat;background-size: cover;}
.position-rela{position: relative;}
.position-abso{position: absolute;}
.right10{right: 10px}
.top{top: 0}
.top10{top: 10px}
.top-center{top: 50%;transform: translateY(-50%);}
.color-white{color: #fff;}
._color{color: #187AB0}
.color-red{color: red}
.bottom-0{bottom: 0;}
.bottom-5{bottom: 5px;}
.left-center{left: 50%;transform: translateX(-50%);}
.text-center{text-align: center;}
.text-right{text-align: right;}
.width{width: 100vw;height: 100vh;}
.width1{width: 100%;height: 100vh;}
.width1001{width: 100%;}
.width501{width: 50%;}
.width601{width: 60%;}
.height1001{width: 100%;}
.height50{height: 50vh;}
.z-index-1{z-index: 1;}
.showhome-nav{position: fixed;top: 0;bottom: 0;left: 0;right: 0;background: #fff;}
.showhome-nav .con{width: 80%;margin: 30px auto}
.showhome-nav .con>div{margin-top: 10px}
.overflow{overflow: hidden;}
.flex{display: flex;}
.flexwrap{flex-wrap: wrap;}
.flexcenter{align-items: center;}
.flexcenter1{justify-content: center;}
.flexbetween{justify-content: space-between;}
.flex-1{flex: 1;}
.product>div{width: 48.5%;}
.padding10{padding: 10px;}
.padding40{padding: 40px 0;}
.padding80{padding: 80px 0;}
.padding01{padding: 1%;}
.padding01:nth-child(2n+1){padding-right: 0.5%;}
.padding01:nth-child(2n){padding-left: 0.5%;}
.margin-left5{margin-left: 5px;}
.margin-left10{margin-left: 10px;}
.margin-top10{margin-top: 10px;}
.margin-top20{margin-top: 20px;}
.margin-top-4{margin-top: -4px;}
.margin20-0{margin: 20px 0;}
.margin40-0{margin: 20px 0;}
.margin-bottom-4{margin-bottom: -4px;}
.img{width: 100%;height: 100%;object-fit: cover;cursor: pointer;}
.img-qrcode{width: 100px;height: 100px;}
.cursor{cursor: pointer;}
._bg{background: #187AB0;color: #fff}
.bgWhite{background: #fff}
.bgf2{background: #f2f2f2;}
.footerSt{flex-wrap: wrap;}
.footerSt>div{width: 100%;flex: unset;margin-top: 20px}
.info{padding-top: 65px;padding-bottom: 50px}
.prewrap{white-space: pre-wrap;}
.line-height{line-height: 30px;}
/* border: 1px solid #f2f2f2; */
.select{height: 30px;width: 80px;text-align: center;}
.select option{height: 30px;text-align: center;}
.animate__animated.animate__fadeInUp {
  --animate-duration: 2s;
}
.imgH{height: 400px;}
@media (max-width:1460px){
  .imgH{height: 340px;}
}
@media (max-width:1200px){
  .imgH{height: 280px;}
}
@media (max-width:1024px){
  .imgH{height: 200px;}
}
@media (max-width:768px){
  .imgH{height: 170px;}
}
@media (max-width:412px){
  .imgH{height: 140px;}
}

.container{width: 100%;margin: 0 auto;padding: 20px 0}
@media (min-width: 1200px){
  .container{max-width: 1140px;}
}
@media (min-width: 992px) and (max-width: 1200px){
  .container{max-width: 960px;}
}
@media (min-width: 768px) and (max-width: 992px){
  .container{max-width: 720px;}
}
@media (min-width: 576px) and (max-width: 768px){
  .container{max-width: 540px;}
}
</style>
